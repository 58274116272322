<template>
    <section>
        <modal ref="modalJustificacionInactividad" titulo="Justificacion Inactividad">
            <div class="row mx-0 mb-3">
                <div class="col">
                    <p class="f-14 text-muted2 text-center">
                        <strong>
                            Motivo:
                        </strong>
                        Suspensión por pago
                    </p>
                </div>
            </div>
            <div class="row mx-0 mb-4">
                <div class="col px-5">
                    <p class=" text-muted2 f-14">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    methods: {
        toggle(){
            this.$refs.modalJustificacionInactividad.toggle();
        }
    }
}
</script>
